import React, {useState, Fragment, useEffect} from "react";
import Select from "react-select";

const Generation = (props) => {
  const {markers, setStep, stepData, callback, skey} = props;
  const {
    name: pname = "", format: pformat = "pdf", prefix: dprefix,
    watermark:dwatermark, passwordColumn: dpasswordColumn
  } = stepData;

  const formatOptions = [
    {value: 'docx', label: 'DOCX'},
    {value: 'pdf', label: 'PDF'}
  ];
  const [name, setName] = useState(pname);
  const [format, setFormat] = useState(pformat);
  const [prefix, setPrefix] = useState(dprefix || "");
  const [prefixOptions, setPrefixOptions] = useState([]);
  const [watermark, setWatermark] = useState(dwatermark || false);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [passwordColumn, setPasswordColumn] = useState(dpasswordColumn || "");

  useEffect(() => {
    const tmpPrefixOption = [...Object.keys(markers)].reduce((data, key) => {
      if(markers[key] !== true){
        const tableData = [...Object.keys(markers[key])].reduce((data, fkey) => {
          data.push({
            value: key, label: `${key.replace(/_/g, ' ')} - ${fkey.replace(/_/g, ' ')}`
          });
          return data;
        }, []);
        return [...data, ...tableData];
      }
      data.push({value: key, label: key.replace(/_/g, ' ')});
      return data;
    }, []);
    setPrefixOptions(tmpPrefixOption);
  }, [markers]);

  useEffect(() => {
    if(name && format){
      callback(skey, {
        name, format, prefix, watermark, passwordColumn
      })
      setDisableNextButton(false);
      return;
    }
    setDisableNextButton(true);
  }, [name, format, prefix, watermark, passwordColumn, skey, callback]);

  return (
    <Fragment>
      <div className="form-container">
        <div className="form-group has-field">
          <label htmlFor="file-format">File format <span>*</span></label>
          <Select
            id="file-format"
            className="input-select"
            value={
              formatOptions.filter(option => option.value === format)
            } 
            isSearchable={true} 
            name="format" 
            placeholder="Select format of the generated file" 
            options={formatOptions} 
            onChange={(e) => setFormat(e.value)}
          />
        </div>
        <div className="form-group has-field">
          <label htmlFor="file-name">File name <span>*</span></label>
          <input 
            type="text" 
            id="file-name" 
            className="input-field" 
            placeholder="Write down the name of the generated file" 
            autoComplete="off" 
            onChange={(e) => setName(e.target.value)} 
            required="required" 
            value={name}
          />
        </div>
        <div className="form-group has-field">
          <label htmlFor="file-prefix">File name prefix</label>
          <Select 
            id="file-prefix" 
            className="input-select" 
            value={prefixOptions.filter(option => option.value === prefix)} 
            isSearchable={true} 
            name="prefix" 
            placeholder="Select the prefix of the generated each file" 
            options={prefixOptions} 
            onChange={(e) => setPrefix(e.value)}
          />
        </div>
        {format === 'pdf' && 
          <Fragment>
            <div className="form-group has-field">
              <label htmlFor="password-column">Password Column</label>
              <Select 
                id="password-column" 
                className="input-select" 
                value={prefixOptions.filter(option => option.value === passwordColumn)} 
                isSearchable={true} 
                name="prefix" 
                placeholder="Select the column for password field" 
                options={prefixOptions} 
                onChange={(e) => setPasswordColumn(e.value)}
              />
            </div>
            <div className="form-group has-field">
              <label htmlFor="header">Watermark needed?</label>
              <div className="flex-container vertical-middle radio-selection">
                <div className="flexbox half">
                  <label className="custom-input" data-type="radio">
                    Yes
                    <input 
                      type="radio"
                      name="watermark"
                      value="false"
                      onChange={() => setWatermark(true)}
                      checked={watermark === true}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="flexbox half">
                  <label className="custom-input" data-type="radio">
                    No
                    <input 
                      type="radio"
                      name="watermark"
                      value="false"
                      onChange={() => setWatermark(false)}
                      checked={watermark === false}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </Fragment>
        }
      </div>
      <div className="footer text-right">
        <button 
          className="btn" 
          onClick={() => setStep(3)}
        >Back</button>
        <button 
          className="btn moral" 
          disabled={disableNextButton}
          onClick={() => setStep(5)}
        >Next Step</button>
      </div>
    </Fragment>
  )
};

export default Generation;
