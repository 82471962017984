import React, { Fragment } from "react";
import EmailDistribution from "../email-distribution";

const AutomationDistribution = (props) => {
  const {headers, setStep, stepData, callback, skey, submit, isEdit} = props;

  return (
    <Fragment>
      <div className="form-container">
        <EmailDistribution
          type="db"
          tab="email"
          headers={headers}
          skey={skey}
          data={stepData}
          callback={callback}
        />
      </div>
      <div className="footer text-right">
        <button 
          className="btn" 
          onClick={() => setStep(4)}
        >Back</button>
        <button 
          className="btn moral" 
          onClick={submit}
        >{isEdit ? "Edit" : "Create"} automation</button>
      </div>
    </Fragment>
  )
};

export default AutomationDistribution;