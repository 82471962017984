import React from "react";
import Loader from "../loader";
import dltBtn from "../../static/svg/delete.svg";

const DeleteConfirmModal = (props) => {
  const {
    disableStatus, desc, buttonText, isLoading = false,
    disable=() => {}, close, doDelete, multiDelete=false, doMultiDelete
  } = props;

  return (
    <div className="full-page-overlay">
      <div className="flex-container full-height vertical-middle">
        <div className="template-uploader">
          <div className="modal-body relative">
            <div className="heading">Are you sure?</div>
            {!isLoading &&
              <div
                className="close"
                onClick={() => close(false)}
              >
                <img src={dltBtn} alt="close" />
              </div>
            }
            <p className="desc">{desc}</p>
            <div className="footer flex-container no-margin flex-end">
              <button
                className="btn"
                disabled={isLoading}
                onClick={() => close(false)}
              >Cancel</button>
              <button
                className="btn flex moral"
                disabled={disableStatus}
                onClick={() => {
                  disable(true);
                  setTimeout(() => {
                    if(multiDelete){
                      doMultiDelete();
                      return;
                    }
                    doDelete();
                  });
                }}
              >
                {isLoading && <Loader/>}
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DeleteConfirmModal;