import React, { Fragment, useState, useEffect, useCallback } from "react";
import { config } from "../../components/constant";

import Head from "../../components/head";
import Header from "../../components/dashboard/header";
import SideNav from "../../components/dashboard/side-navbar";
import ListItem from "../../components/dashboard/list-item";
import DocumentGenerator from "../../components/dashboard/document-generator";
import CloudStorage from "../../components/dashboard/integrations/cloud-storage";

import { useContextData } from "../../store/ContextData";
import { RequestHook } from "../../components/hookRequest";
import { commonFunction } from "../../components/common-functions";
import DeleteConfirmModal from "../../components/dashboard/delete-confirm";
import EmailDistribution from "../../components/dashboard/email-distribution";
import useDeleteMultipleOutput from "../../components/dashboard/delete-multiple-output";

// Importing all images
import dltBtn from "../../static/svg/delete.svg";
import dlt from "../../static/images/delete.png";
import refresh from "../../static/images/refresh.png";
import listActive from "../../static/svg/list.active.svg";
import listInActive from "../../static/svg/list.inactive.svg";
import gridActive from "../../static/svg/grid.active.svg";
import gridInActive from "../../static/svg/grid.inactive.svg";

const Output = () => {
  const {nanobar} = useContextData();
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const [output, setOutput] = useState([]);
  const [subject, setSubject] = useState("");
  const [markerData, setMarkerData] = useState({});
  const [viewType, setViewType] = useState("list");
  const [selectedOutput, setSelectedOutput] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [sendEmailModal, setSendEmailModal] = useState(false);
  const [editOutputFile, setEditOutputFile] = useState(false);
  const [selectedOutputId, setSelectedOutputId] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [printSubmitData, setPrintSubmitData] = useState({});
  const [showCloudStorage, setShowCloudStorage] = useState(false);
  const [disableUploadButton, setDisableUploadButton] = useState(true);
  const [cloudStorageData, setCloudStorageData] = useState({
    platform: "",
    data: "",
    fileId: "",
    fileName: "",
    blob: undefined
  });

  const downloadFile = useCallback((id, filename, openInNewTab = false, needBlob=false, callback) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    
    nanobar.current.classList.add("running");
    nanobar.current.setAttribute("data-state", "running");

    fetch(
      `${config.API_URL}api/v1/output/download/${id}?access_token=${token}`
    )
    .then((resp) => resp.blob())
    .then((blob) => {
      if (
        nanobar.current.classList.contains("running") &&
        nanobar.current.getAttribute("data-state") === "running"
      ){
        nanobar.current.setAttribute("data-state", "paused");
      }

      if(needBlob && callback){
        callback(blob);
        return;
      }

      const url = window.URL.createObjectURL(blob);
      if(openInNewTab){
        const tab = window.open();
        tab.location.href = url;
        if(callback){
          callback(true);
        }
        return;
      }

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    })
    .catch(() => {
      commonFunction.showAlert("Unable to download the file!", 'error');
    });
  }, [nanobar]);

  const outputLink = `api/v1/output/`;
  const {
    loading:outputLoading, data:outputData, callAPI:outputCall
  } = RequestHook(outputLink);
  useEffect(() => {
    if(!outputLoading && outputData){
      setOutput([]);
      setTimeout(() => {
        const {output} = outputData;
        setOutput(output);
      });
    }
  }, [outputLoading, outputData]);

  const documentGenerate = `api/v1/document/generate/`;
  const {
    loading:generateLoading, data:generateData, callAPI:generateCall
  } = RequestHook(documentGenerate, false, submitData, 'POST');
  useEffect(() => {
    if(!generateLoading && generateData){
      setSelectedOutputId("");
      setMarkerData({});
      setEditOutputFile(false);

      const {message} = generateData;
      commonFunction.showAlert(`Nice! ${message}`);
    }
  }, [generateLoading, generateData]);

  const outputMerge = `api/v1/output/merge/`;
  const {
    loading:printLoading, data:printData, callAPI:printCall,
    error:printError, errorMessage: printErrorMessage
  } = RequestHook(outputMerge, false, printSubmitData, 'POST');
  useEffect(() => {
    if(!printLoading && printData){
      const {id, filename} = printData;
      downloadFile(id, filename, true, false, () => {
        setPrintSubmitData({});
      });
    }
  }, [printLoading, printData, downloadFile]);

  useEffect(() => {
    if(printError && printErrorMessage){
      commonFunction.showAlert(printErrorMessage, 'error');
    }
  }, [printError, printErrorMessage]);

  const emailDocument = `api/v1/output/email/`;
  const {
    loading:emailLoading, data:emailData, callAPI:emailCall
  } = RequestHook(emailDocument, false, {
    outId: selectedOutputId,
    emailId: email,
    subject, body
  }, 'POST');
  useEffect(() => {
    if(!emailLoading && emailData){
      setDisabledButton(false);
      setSendEmailModal(false);
      setSelectedOutputId("");
      setTimeout(() => {
        setEmail("");
        setBody("");
        setSubject("");
        const {message} = emailData;
        commonFunction.showAlert(`Awesome! ${message}.`);
      });
    }
  }, [emailLoading, emailData]);

  const deleteLink = `api/v1/output/${selectedOutputId}/`;
  const {
    loading:deleteLoading, data:deleteData, callAPI:deleteCall
  } = RequestHook(deleteLink, false, {}, 'DELETE');
  useEffect(() => {
    if(!deleteLoading && deleteData){
      const {status} = deleteData;
      if(status === 200){
        setDisabledButton(false);
        setSelectedOutputId("");
        setShowDeleteConfirm(false);
        commonFunction.showAlert(`Okay! the output file has been deleted successfully.`);
        setTimeout(() => {
          outputCall();
        })
      }
    }
  }, [deleteLoading, deleteData, outputCall]);

  const {platform, data, blob, fileName} = cloudStorageData;
  const uploadLink = (
    `api/v1/${platform}/${platform === 'sharepoint' ? `drives/${data}/` : `upload-file/?path=${
      data === "/" ? "." : data
    }`}`
  );
  const formData = new FormData();
  if(blob){
    formData.append("file", blob, fileName);
  }
  const {
    loading:uploadLoading, data:uploadData, callAPI:uploadCall,
    error:uploadError, errorMessage: uploadErrorMessage
  } = RequestHook(uploadLink, false, formData, 'POST');
  useEffect(() => {
    if(fileName && platform && !uploadLoading && uploadData){
      setShowCloudStorage(false);
      const {status} = uploadData;
      commonFunction.showAlert(
        (status ? 
        `Okay, ${fileName} has been successfully uploaded to ${platform}!` : 
        "Oops, Unable to upload the file!"), 
        status ? "success" : 'error'
      );
      setCloudStorageData({
        platform: "",
        data: "",
        fileId: "",
        fileName: "",
        blob: undefined
      });
      setDisableUploadButton(false);
    }
  }, [fileName, platform, uploadLoading, uploadData]);

  useEffect(() => {
    if(uploadError && uploadErrorMessage){
      commonFunction.showAlert(
        "Oops, Unable to upload the file!", 
        'error'
      );
      setDisableUploadButton(false);
    }
  }, [platform, uploadError, uploadErrorMessage]);

  const [isDone, startDelete, isStarted] = useDeleteMultipleOutput(selectedOutput);
  useEffect(() => {
    if(isDone){
      setSelectedOutput([]);
      setDisabledButton(false);
      setShowDeleteConfirm(false);
      commonFunction.showAlert(`Okay! the output file has been deleted successfully.`);
      setTimeout(() => {
        outputCall();
      });
    }
  }, [isDone, outputCall]);

  const selectAll = () => {
    if(selectedOutput.length === output.length){
      setSelectedOutput([]);
      return;
    }
    setSelectedOutput(output.map(el => el._id));
  };

  const setAction = useCallback((type, id, filename) => {
    switch(type){
      case 'download':
        downloadFile(id, filename);
      break;

      case 'email':
        setSelectedOutputId(id);
        setSendEmailModal(true);
      break;

      case 'delete':
        setSelectedOutputId(id);
        setShowDeleteConfirm(true);
      break;

      case 'print':
        filename = filename.split(".");
        filename.pop(filename.length - 1);
        filename.push("docx");
        filename = filename.join(".");

        setPrintSubmitData({
          outputIds: [id],
          mergedFileName: filename
        });
        setTimeout(() => {
          printCall();
        });
      break;

      case 'edit':
        const {input} = output.find(el => el._id === id);
        if(input){
          const {documentId, markers} = input;
          setSelectedOutputId(documentId);
          setMarkerData(markers);
          setEditOutputFile(true);
        }
      break;

      case 'upload':
        setShowCloudStorage(true);
        setCloudStorageData((cloudStorageData) => {
          return {
            ...cloudStorageData,
            fileId: id,
            fileName: filename
          }
        });
      break;

      default:
      break;
    }
  }, [output, downloadFile, printCall]);

  const uploadFile = () => {
    setDisableUploadButton(true);
    const {fileId, fileName} = cloudStorageData;
    downloadFile(fileId, fileName, false, true, (blob) => {
      setCloudStorageData((cloudStorageData) => {
        return {
          ...cloudStorageData,
          blob
        }
      });
      setTimeout(() => {
        uploadCall();
      });
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setDisabledButton(true);
    emailCall();
  };

  const cloudStorageDataCallback = (platform, data) => {
    setCloudStorageData((cloudStorageData) => {
      return {
        ...cloudStorageData,
        platform, data
      }
    });
    setDisableUploadButton(false);
  };

  return (
    <Fragment>
      <Head customTitle={"Output"} />
      <Header></Header>
      <SideNav index={6}></SideNav>
      <main className="dashboard-container template">
        <div className="main-container">
          <div className="body">
            <div className="flex-container">
              <div className="flexbox two-third left">
                <div className="row-heading text-uppercase">
                  <div className="flex-container vertical-middle">
                    <div className="flexbox select-all">
                      <div className="form-group">
                        <label className="custom-input" data-type="checkbox">
                          <input
                            type="checkbox"
                            name="select-all"
                            value="true"
                            onChange={() => selectAll()}
                            checked={selectedOutput.length > 0 && selectedOutput.length === output.length}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="flexbox name">
                      {viewType === "list" && <span>Name</span>}
                    </div>
                    <div className="flexbox view-type">
                      <div className="flex-container view vertical-middle">
                        <div
                          className={`flexbox list ${viewType === "list" ? "active" : ""}`}
                          onClick={() => setViewType("list")}
                        >
                          <img
                            src={viewType === "list" ? listActive : listInActive}
                            alt="icon"
                          />
                          <span>List</span>
                        </div>
                        <div
                          className={`flexbox grid ${viewType === "grid" ? "active" : ""}`}
                          onClick={() => setViewType("grid")}
                        >
                          <img
                            src={viewType === "grid" ? gridActive : gridInActive}
                            alt="icon"
                          />
                          <span>Grid</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${viewType}-container`}>
                  {output.map((el, index) => (
                    <div
                      className={`data-row ${viewType}`}
                      key={index}
                    >
                      <ListItem
                        type={viewType}
                        filename={el.originalFileName || el.filename}
                        date={el.addedAt}
                        size={el.size}
                        elid={el._id}
                        action={setAction}
                        section="output"
                      >
                        <div className="form-group">
                          <label
                            className="custom-input"
                            data-type="checkbox"
                          >
                            <input
                              type="checkbox"
                              name="select[]"
                              value={el._id}
                              checked={
                                selectedOutput.indexOf(el._id) > -1
                              }
                              onChange={() => {
                                const index = selectedOutput.indexOf(el._id);
                                const tmpSelectedOutput = [...selectedOutput];
                                if(index === -1) tmpSelectedOutput.push(el._id);
                                else tmpSelectedOutput.splice(index, 1);

                                setSelectedOutput(tmpSelectedOutput);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </ListItem>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flexbox one-third right">
                <div
                  className={
                    selectedOutput.length > 0 ? "btn-link" : "btn-link disabled"
                  }
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  <img src={dlt} className="delete icon" alt="icon" />
                  Delete output files
                </div>

                <div
                  className="btn-link"
                  onClick={() => outputCall()}
                >
                  <img src={refresh} className="delete icon" alt="icon" />
                  Fetch latest output files
                </div>
              </div>
            </div>
          </div>
        </div>
          {editOutputFile && (
          <DocumentGenerator
            tid={selectedOutputId}
            callback={() => {
              setSelectedOutputId("");
              setMarkerData([]);
              setEditOutputFile(false);
            }}
            type="input"
            fdata={markerData}
            submit={(type, submitData) => {
              if(type === "input"){
                setSubmitData(submitData);
                setTimeout(() => {
                  generateCall();
                })
              }
            }}
            hidedraft={true}
          />
        )}
        
        {showDeleteConfirm && 
          <DeleteConfirmModal
            disableStatus={disabledButton}
            desc={"This will delete the generated output file, once deleted\
            you will not be able to recover it."}
            buttonText={isStarted && !isDone ? "Deleting..." : "Delete output files"}
            disable={setDisabledButton}
            close={setShowDeleteConfirm}
            doDelete={deleteCall}
            multiDelete={selectedOutput && selectedOutput.length > 0}
            doMultiDelete={startDelete}
            isLoading={isStarted && !isDone}
          />
        }

        {sendEmailModal && (
          <div className="full-page-overlay">
            <div className="flex-container full-height vertical-middle">
              <div className="template-uploader">
                <div className="modal-body relative">
                  <div className="heading">Email output file as attachment</div>
                  <div
                    className="close"
                    onClick={() => setSendEmailModal(false)}
                  >
                    <img src={dltBtn} alt="close" />
                  </div>
                  <p className="desc">
                    Write down the email address in comma separated way along
                    with subject and body of the email.
                  </p>
                  <form method="post" onSubmit={(e) => sendEmail(e)}>
                    <div className="form-group has-field">
                      <label htmlFor="email-address">
                        Email address<span>*</span>
                      </label>
                      <input
                        type="email"
                        id="email-address"
                        className="input-field"
                        placeholder="Write down the email of the recipient"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        required="required"
                        value={email}
                      />
                    </div>
                    <EmailDistribution
                      type="input"
                      callback={(e, data) => {
                        const {subject="", body=""} = data;
                        setSubject(subject);
                        setBody(body);
                      }}
                      data={{
                        subject,
                        body
                      }}
                      required={true}
                    />
                    <div className="footer text-right">
                      <button
                        className="btn"
                        type="button"
                        onClick={() => setSendEmailModal(false)}
                      >Cancel</button>
                      <button
                        className="btn moral"
                        disabled={!email || !body || !subject || disabledButton}
                        type="submit"
                      >Send email</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {showCloudStorage && (
          <div className="full-page-overlay">
            <div className="flex-container full-height vertical-middle">
              <div className="template-uploader cloud-storage">
                <div className="modal-body relative">
                  <div className="heading">Select platform to upload</div>
                  <div
                    className="close"
                    onClick={() => {
                      setShowCloudStorage(false);
                      setCloudStorageData({
                        platform: "",
                        data: "",
                        fileId: "",
                        fileName: "",
                        blob: undefined
                      });
                    }}
                  >
                    <img src={dltBtn} alt="close" />
                  </div>
                  <CloudStorage
                    callback={cloudStorageDataCallback}
                    data={cloudStorageData}
                  />
                  {cloudStorageData.platform && disableUploadButton && 
                    <div className="loading-screen">
                    Downloading file from edocgen server...
                    </div>
                  }
                  <div className="footer text-right">
                    <button
                      className="btn"
                      type="button"
                      onClick={() => {
                        setShowCloudStorage(false);
                        setCloudStorageData({
                          platform: "",
                          data: "",
                          fileId: "",
                          fileName: "",
                          blob: undefined
                        });
                      }}
                    >Cancel</button>
                    <button
                      className="btn moral"
                      disabled={disableUploadButton}
                      onClick={() => uploadFile()}
                    >Upload file</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </Fragment>
  );
};

export default Output;
