export const steps = [{
  title: "Fillout the form fields",
  desc: "Fillout all the fields in the form to create automation",
  moreDesc: "Select a uploaded template and provide a name of the automation to proceed to next step"
}, {
  title: "Database connection details",
  desc: "Fillout all the database connection details to generate document",
  moreDesc: "Fill the database connection details and click on the connect button and then next button to go to next step"
}, {
  title: "Map fields",
  desc: "Map uploaded data fields with the fields of the template",
  moreDesc: `Map template fields with the fields present in the uploaded data, 
  by drag and drop the field to it's corresponding field.`
}, {
  title: "Schedule automation",
  desc: "Select the date and time to automate the document generation",
  moreDesc: `Set cronjob to generate document automatically and available 
  to output file on your email or on your ftp server`
}, {
  title: "Format and filename",
  desc: "Generate document by providing name of the file and the format",
  moreDesc: `Automate the document generation process by providing the generated 
  file name and the output file format.`
}, {
  title: "Distribution",
  desc: "Share the generated document with someone by email",
  moreDesc: `Automate the distribution of the generated document by selecting email 
  field from the database column.`
}];

export const printFormatValue = [{
  label: "PCL", 
  value: "pcl"
}, {
  label: "PostScript", 
  value: "post-script"
}];
