import React from "react";

const Loader = (props) => {
  const {width = 18, height = 18} = props;
  return (
    <div style={{width, height}} className="loader"></div>
  );
};

export default Loader;
