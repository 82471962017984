import { useState, useEffect, useRef } from 'react';
import { RequestHook } from "../hookRequest";

const useDeleteMultipleOutput = (outputIds) => {
  const selectedIdsRef = useRef();
  const [isDone, setIsDone] = useState(false);
  const [startCall, setStartCall] = useState(false);
  const [selectedOutputId, setSelectedOutputId] = useState();
  
  const deleteLink = `api/v1/output/${selectedOutputId}/`;
  const {
    loading:deleteLoading, data:deleteData, callAPI:deleteCall
  } = RequestHook(deleteLink, false, {}, 'DELETE');

  useEffect(() => {
    if(!deleteLoading && deleteData){
      if(selectedIdsRef.current.length === 0){
        setStartCall(false);
        setIsDone(true);
        return;
      }
      setSelectedOutputId(selectedIdsRef.current.pop());
    }
  }, [deleteLoading, deleteData]);

  useEffect(() => {
    if(startCall && !isDone){
      setTimeout(() => {
        deleteCall();
      });
    }
  }, [selectedOutputId, isDone, startCall, deleteCall])

  useEffect(() => {
    selectedIdsRef.current = [...outputIds];
    setSelectedOutputId(selectedIdsRef.current.pop());
  }, [outputIds]);

  const startDelete = () => {
    setIsDone(false);
    setStartCall(true);
  };

  return [isDone, startDelete, startCall];
}

export default useDeleteMultipleOutput;