import React, {Component} from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Alert from 'react-s-alert';
import Select from 'react-select';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import Head from "../components/head";
import { config } from '../components/constant';
import { commonFunction } from '../components/common-functions';

import Logo from "../static/images/logo.png";
import dlt from "../static/images/delete.png";
import plus from "../static/images/plus.png";
import refresh from "../static/svg/ring-alt.svg"
// import dltBtn from "../../static/svg/delete.svg";


require("react-datepicker/dist/react-datepicker.css");
require('../static/css/public-form.css');

const CustomInput = React.forwardRef((props, ref) => (
    <input
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
        ref={ref}
    />
))


class PublicForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            fetched: false,
            token: undefined,
            tid: this.props.match.params.templateId,
            did: this.props.match.params.draftId,
            activeTabIndex: 0,
            markers: {},
            validationObject: {},
            formData: {},
            tabGroup: {},
			tabs: [],
			stepValidation: [],
			format: 'pdf',
            logo: undefined,
			name: `${commonFunction.makeId(10)}_${commonFunction.makeId(10)}`
        }
    }

    setMarkerAndValidationObject(markers, metadata, draft){
        var validationObject = {},
            markerObject = {},
            key,
            i = 0,
            formData = {},
			arr,
			dres = commonFunction.isObjectValid(draft),
			hasDraft = dres.key > 0 ? true : false;
        
        for(key in markers){
            if(markers[key] === true){ 
                markerObject[key] = metadata[key];
                markerObject[key].name = key.replace(/_/g, ' ');

				if(hasDraft){
					if(metadata[key].hasOption){
						if(draft[key] !== undefined) formData[key] = metadata[key].type === 'checkbox' ? [draft[key]] : (metadata[key].type === 'date') ? dayjs(draft[key], metadata[key].format).$d : draft[key];
						else formData[key] = '';
					}
					else formData[key] = draft[key] || '';
				}
				else{
					if(metadata[key].hasOption){
						if(metadata[key].type !== 'file' && metadata[key].type !== 'date'){
							if(metadata[key].type === 'checkbox'){ 
								formData[key] = [];
								arr = metadata[key].options.filter(option => option.default);
								for(i = 0; i<arr.length; i++)
									formData[key].push(arr[i].value);
							}
							else{
								arr = metadata[key].options.filter(option => option.default);
								formData[key] = arr[0].value;
							}
						}
						else formData[key] = '';
					}
				}
				
				validationObject[key] = {
                    type: metadata[key].type,
                    valid: metadata[key].required ? (formData[key] ? true : false) : true,
                    touched: false
                }
            }
            else{
                markerObject[key] = {};
                markerObject[key].type = 'table';
                markerObject[key].name = key.replace(/_/g, ' ');
                var fields = [],
                    vobj = {
                        type: 'table',
						valid: draft[key] && draft[key].length > 0 ? true : false,
						formValid: false,
                        sindex: [],
                        fields: {}
					},
					fkey;
				
				for(fkey in markers[key]){
					fields.push({
                        key: fkey,
                        name: fkey.replace(/_/g, ' ')
                    });
                    vobj.fields[fkey] = '';
				}
                markerObject[key].fields = fields;
                formData[key] = draft[key] || [];
                validationObject[key] = vobj;
            }
        }

        return {
            marker: markerObject,
            validation: validationObject,
            form: formData
        }
	}
	
	checkAndValidate(step){
		var tab = this.state.tabs[step],
			group = this.state.tabGroup[tab],
			i = 0,
			counter = 0,
            stepValidation = [...this.state.stepValidation];
            
		for(; i<group.length; i++){
			if(
                this.state.validationObject[group[i]].valid || 
                !this.state.markers[group[i]].required
            ) counter++;
            else break;
        }

		if(group.length === counter) stepValidation[step] = true;
		else stepValidation[step] = false;
		
		this.setState({
			stepValidation: stepValidation
		})
	}

    validateAndSwitchTab(e, index){
        e.preventDefault();
		if(index !== this.state.activeTabIndex){
			if(this.state.stepValidation[index === 0 ? index : index - 1]) this.setState({
				activeTabIndex: index
			}, () => {
				this.checkAndValidate(this.state.activeTabIndex);
			});
			else Alert.error('Please fill up all the data in current tab, to go to next step.', {
				position: 'bottom-right',
				effect: 'slide',
				timeout: 5000
			});
		}
    }

    deleteTableRow(key){
        if(this.state.validationObject[key].sindex.length > 0){
            var data = JSON.parse(JSON.stringify(this.state.formData)),
                tableInputs = JSON.parse(JSON.stringify(this.state.validationObject)),
                i = 0,
                indexes = commonFunction.quickSort(tableInputs[key].sindex);
            
            for(i = indexes.length - 1; i >= 0; i--)
				data[key].splice(indexes[i], 1);
				
			tableInputs[key].valid = data[key].length > 0;
            tableInputs[key].sindex = [];
            this.setState({
                formData: data,
                validationObject: tableInputs
            }, () => {
				this.checkAndValidate(this.state.activeTabIndex);
            });
        }
	}

	selectTableRow(skey, tableKey){
		var tableInputs = JSON.parse(JSON.stringify(this.state.validationObject)),
			index = tableInputs[skey].sindex.indexOf(tableKey);
		
		if(index === -1) tableInputs[skey].sindex.push(tableKey);
		else tableInputs[skey].sindex.splice(index, 1);

		this.setState({
			validationObject: tableInputs
		})
	}
	
	addTableRow(key){
		if(this.state.validationObject[key].formValid){
			var data = JSON.parse(JSON.stringify(this.state.formData)),
				tableInputs = JSON.parse(JSON.stringify(this.state.validationObject)),
				skey;

			data[key].push({ ...tableInputs[key].fields });
			tableInputs[key].valid = data[key].length > 0;
			tableInputs[key].formValid = false;
			for(skey in tableInputs[key].fields)
				if(tableInputs[key].fields.hasOwnProperty(skey)) tableInputs[key].fields[skey] = "";
			
			this.setState({
				formData: data,
				validationObject: tableInputs
			}, () => {
				this.checkAndValidate(this.state.activeTabIndex);
			});
		}
	}

	bindEnter(e, key){
        if(e.keyCode === 13)
            if(this.state.validationObject[key].formValid)
                this.addTableRow(key);
    }

	setFormData(value, key, fkey){
		var emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,5}$/,
            obj = {...this.state.validationObject},
            data = obj[key],
			fd = {...this.state.formData};
			
		data.touched = true;
		switch(data.type){
			case 'table':
				data.fields[fkey] = value;
				var response = commonFunction.isObjectValid(data.fields);
				data.formValid = (response.key === response.flag);
			break;

            case 'email':
                fd[key] = value;
                if(emailRegex.test(value)) data.valid = true;
                else{
                    if(!this.state.markers[key].required) data.valid = true;
                    else data.valid = false;
                }
            break;

            case 'checkbox':
                if(fd[key] === undefined) fd[key] = [];
                var index = fd[key].indexOf(value);

                if(index === -1) fd[key].push(value);
                else fd[key].splice(index, 1);

                if(fd[key].length > 0) data.valid = true;
                else data.valid = false;
            break;

            case 'file':
            break;

            default:
                fd[key] = value;
                if(value || !this.state.markers[key].required) data.valid = true;
                else data.valid = false;
            break;
		} 
		obj[key] = data;
        this.setState({
            formData: fd,
            validationObject: obj
        }, () => {
			this.checkAndValidate(this.state.activeTabIndex);
		})
	}

    getField(key, index){
        var meta = this.state.markers[key],
            label = (
                <label htmlFor={key}>{meta.name}
                    {(meta.required || meta.type === 'table') && <span>*</span>}
                </label>
            ),
            helpText = meta.helpText ? (
                <p className="help-text">{meta.helpText}</p>
            ) : undefined,
            input = undefined;

        switch(meta.type){
            case 'table':
                input = (
                    <table className={`table`}>
                        <thead>
                            <tr>
                                <th>
                                    <img src={dlt} className={`icon ${this.state.validationObject[key].sindex.length === 0 ? 'disabled' : ''}`} alt="icon" onClick={() => this.deleteTableRow(key)}/>
                                </th>
                                {
                                    meta.fields.map((fel, findex) => (
                                        <th key={findex}>{fel.name}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
							{
								this.state.formData[key].map((del, dindex) => (
									<tr key={dindex}>
										<td>
											<label className="custom-input" data-type="checkbox">
												<input type="checkbox" name="troe[]" onChange={() => this.selectTableRow(key, dindex)} checked={this.state.validationObject[key].sindex.indexOf(dindex) > -1}/>
												<span className="checkmark"></span>
											</label>
										</td>
										{
											meta.fields.map((fel, findex) => (
												<td key={findex}>{del[fel.key]}</td>
											))
										}      
									</tr>
								))
							}
                            <tr className="input">
                                <td>
                                    <img src={plus} className={`plus icon${this.state.validationObject[key].formValid?'' : ' disabled'}`} alt="icon" onClick={() => this.addTableRow(key)}/>
                                </td>
                                {
									meta.fields.map((fel, findex) => (
										<td key={findex}>
											<input type="text" className="input-field" placeholder={`Data for "${fel.name.toLowerCase()}"`} autoComplete="off" tabIndex={index + findex + 1} required="required" onChange={(e) => this.setFormData(e.target.value, key, fel.key)} value={this.state.validationObject[key].fields[fel.key]} onKeyUp={(e) => this.bindEnter(e, key)}/>
										</td>
									))
                                }
                            </tr>
                        </tbody>
                    </table>
                )
            break;

            case 'date':
                input = (
                    <DatePicker 
                        showMonthDropdown 
                        showYearDropdown 
                        dropdownMode="scroll" 
                        selected={this.state.formData[key] || new Date()} 
                        dateFormat={commonFunction.datePicker[commonFunction.dateFormats.indexOf(meta.format)]} 
                        onChange={(e) => this.setFormData(e, key)} 
                        customInput={<CustomInput ref={React.createRef()}/>}
                    />
                )
            break;

            case 'file':
                input = (
                    <input 
                        type={meta.type} 
                        id={key} 
                        className={`input-field${this.state.validationObject[key].touched && !this.state.validationObject[key].valid ? ' error' : ''}`} 
                        placeholder={`Select file for "${meta.name.toLowerCase()}"`} 
                        autoComplete="off" 
                        tabIndex={index} 
                        required={meta.required} 
                        value={this.state.formData[key] || undefined} 
                        onChange={(e) => {this.setFormData(e.target.value, key)}}
                    />
                )
            break;

            case 'radio':
                input = (
                    <div className="flex-container wrap">
                        {
                            meta.options.map((el, index) => (
                                <div className="flexbox" key={`${meta.type}_${key}_${index}`}>
                                    <label className="custom-input" data-type={meta.type}>
                                        {el.label}
                                        <input type={meta.type} name={`${key}${meta.type === 'checkbox' ? '[]':''}`} value={el.value} checked={this.state.formData[key] === el.value} onChange={() => {this.setFormData(el.value, key)}}/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                )
            break;

            case 'checkbox':
                input = (
                    <div className="flex-container wrap">
                        {
                            meta.options.map((el, index) => (
                                <div className="flexbox" key={`${meta.type}_${key}_${index}`}>
                                    <label className="custom-input" data-type={meta.type}>
                                        {el.label}
                                        <input type={meta.type} name={`${key}${meta.type === 'checkbox' ? '[]':''}`} value={el.value} checked={this.state.formData[key].indexOf(el.value) > -1} onChange={() => {this.setFormData(el.value, key)}}/>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                )
            break;

            case 'dropdown':
                input = (
                    <Select 
                        id={key} 
                        value={meta.options.filter(option => option.value === this.state.formData[key])} 
                        className={`input-select${this.state.validationObject[key].touched && !this.state.validationObject[key].valid ? ' error' : ''}`} 
                        name={`select-${key}`} 
                        options={meta.options} 
                        placeholder={`Select data for "${meta.name.toLowerCase()}"`} 
                        onChange={(e) => {this.setFormData(e, key)}}
                    />
                )
            break;

            case 'textarea':
                input = (
                    <textarea 
                        className={`input-field${this.state.validationObject[key].touched && !this.state.validationObject[key].valid ? ' error' : ''}`} 
                        id={key} 
                        tabIndex={index} 
                        placeholder={`Write down the data for "${meta.name.toLowerCase()}"`} 
                        autoComplete="off" 
                        required={meta.required} 
                        defaultValue={this.state.formData[key]} 
                        onChange={(e) => {this.setFormData(e.target.value, key)}}
                        // onBlur={() => this.setFormData(e.target.value, key)}
                    ></textarea>
                )
            break;

            case 'number':
            case 'email':
            default:
                input = (
                    <input 
                        type={meta.type} 
                        id={key} 
                        className={
                            `input-field${
                                this.state.validationObject[key].touched && 
                                !this.state.validationObject[key].valid ? ' error' : ''
                            }`
                        } 
                        placeholder={`Write down the data for "${meta.name.toLowerCase()}"`} 
                        autoComplete="off" 
                        tabIndex={index} 
                        required={meta.required} 
                        value={this.state.formData[key]} 
                        onChange={(e) => {this.setFormData(e.target.value, key)}}
                    />
                );
            break
        }

        return (
            <div className={meta.helpText ? "has-helptext" : undefined}>
                { label }
                { helpText }
                { input }
            </div>
        )
	}
	
	submitForm(e){
        e.preventDefault();
        var flag = true,
            i = 0,
            key,
            formData = {...this.state.formData};
        
        for(; i < this.state.stepValidation.length; i++)
            if(!this.state.stepValidation[i]){
                flag = false;
                break;
            }

        if(flag){
            for(key in this.state.markers)
                if(this.state.markers[key].type === 'date') formData[key] = dayjs(formData[key]).format(this.state.markers[key].format);
            
            var stepV = [...this.state.stepValidation];
            stepV[stepV.length - 1] = false;
            this.setState({
                stepValidation: stepV 
            }, () => {
                axios.post(`${config.API_URL}api/v1/document/generate/`, {
                    markers: formData,
                    documentId: this.state.tid,
                    "async": true,
                    format: this.state.format,
                    outputFileName: `${this.state.name}.${this.state.format}`
                })
                .then(() => {
                    Alert.success(`Nice! Your data has been submitted successfully.`, {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                })
            })
        }
        else Alert.error(`Sorry, please check and see if all the fields in this form is valid or not.`, {
            position: 'bottom-right',
            effect: 'slide',
            timeout: 5000
        });
	}

    componentDidMount(){
        var  urls = new URLSearchParams(window.location.search),
            token = urls.get('token');

        if(token){
            axios.interceptors.request.use((config) => {
                config.headers['x-access-token'] = `${token}`;
                return config;
            });

            this.setState({
                token: token
            }, () => {
                var callArray = [
                    axios.get(`${config.API_URL}api/v1/document/markers/${this.state.tid}/`),
                    axios.get(`${config.API_URL}api/v1/gettemplatemetadatabyid/${this.state.tid}/`),
                    axios.get(`${config.API_URL}api/v1/gettemplatemetagroupbyid/${this.state.tid}/`)
                ];
                if(this.state.did) callArray.push(axios.get(`${config.API_URL}api/v1/draft/${this.state.did}/`));
                
                axios.all(callArray)
                .then(axios.spread((markers, metadata, metagroup, draft) => {
                    metagroup = metagroup.data[0].data;
                    const { data: mData, format: mFormat, imageFileId } = metadata.data[0];
					var format = mFormat,
						name = this.state.name;
                    if(draft){
						name = `${draft.data.drafts[0].name}_${name}`
						format = draft.data.drafts[0].format;
						draft = draft.data.drafts[0].markers;
					}
                    
                    var data = this.setMarkerAndValidationObject(markers.data.markers, mData, draft ? draft : {}),
                        tabs = [],
						key,
						step = [];

                    for(key in metagroup){
						tabs.push(key);
						step.push(false);
					}

                    this.setState({
                        fetched: true,
                        markers: data.marker,
                        validationObject: data.validation,
                        formData: data.form,
                        tabGroup: metagroup,
						tabs: tabs,
						stepValidation: step,
						format: format,
						name: name,
                        logo: imageFileId
                    }, () => {
						this.checkAndValidate(this.state.activeTabIndex);
					})
                }))
                .catch((e) => {
                    Alert.error('Something went wrong, please get the updated URL from the owner.', {
                        position: 'bottom-right',
                        effect: 'slide',
                        timeout: 5000
                    });
                });
            });
        }
        else Alert.error('Token has not passed in the URL, please copy the url and share, do not modify', {
            position: 'bottom-right',
            effect: 'slide',
            timeout: 5000
        });
    }

    render(){
        const {logo, token} = this.state;
        const logoLink = (
            !logo ? Logo : `${config.API_URL}api/v1/image/download/${logo}?access_token=${token}`
        );
        
        return (
            <div className="public-form">
                <Head customTitle={"Public form"}/>
                {!this.state.fetched && 
                <div>
                    <div className="loading-screen">
                        <div className="flex-container vertical-middle full-height">
                            <div className="flexbox text-center">
                                <div className="logo">
                                    <div className="company-logo">
                                        <img src={logoLink} className="logo" alt="company logo"/>
                                    </div>
                                </div>
                                <p className="logo-text">Generate documents</p>
                                <div className="loading">
                                    <img src={refresh} className="loading" alt="loading"/>
                                    <span id="loading" className="text-left">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.state.fetched && 
                <div>
                    <header>
                        <div className="flex-container full-height vertical-middle text-center">
                            <div className="flexbox">
                                <div className="logo">
                                    <Link to="/" className="company-logo">
                                        <img src={logoLink} className="logo" alt="company logo"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="main-container">
                        <section className="form-section">
                            <div className="tab-container">
                                <div className="tab-heading text-uppercase">
                                    {
                                        this.state.tabs.map((el, index) => (
                                            <div 
                                                className={`tabs${index === this.state.activeTabIndex ? ' active' : ''}`} 
                                                key={index} 
                                                onClick={() => this.validateAndSwitchTab(index)}>{el}</div>
                                        ))
                                    }
                                </div>

                                {
                                    this.state.tabs.length > 0 && this.state.tabs.map((el, index) => {
                                        if(index !== this.state.activeTabIndex) return undefined;
                                        return (
                                            <div className="tab-body" key={index}>
                                                <form 
                                                    encType="multipart/form-data" 
                                                    method="post"
                                                    onSubmit={
                                                        (e) => this.state.activeTabIndex < (this.state.tabs.length - 1) ? this.validateAndSwitchTab(e, this.state.activeTabIndex + 1) : this.submitForm(e)
                                                    }
                                                >
                                                    <div className="form-container">
                                                    {
                                                        this.state.tabGroup[el].map((elm, tindex) => (
                                                            <div key={tindex}>
                                                                <div className="form-group">
                                                                    { this.getField(elm, tindex) }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                    </div>
                                                    <div className="footer button-holder">
                                                        {this.state.activeTabIndex > 0 && 
                                                            <button 
                                                                className="btn" 
                                                                onClick={
                                                                    () => this.validateAndSwitchTab(
                                                                        this.state.activeTabIndex - 1
                                                                    )
                                                                }
                                                            >Previous step</button>}
                                                        <button 
                                                            className="btn moral" 
                                                            disabled={
                                                                !this.state.stepValidation[this.state.activeTabIndex]
                                                            } 
                                                            type="submit"
                                                        >{this.state.activeTabIndex < (this.state.tabs.length - 1) ? 'Next step' : 'Submit'}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </main>
                </div>
                }
            </div>
        )
    }
}

export default PublicForm;