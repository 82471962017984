import React from 'react';
import ReactDOM from 'react-dom';
import Alert from 'react-s-alert';
import Nanobar from "./components/nanobar";
import { BrowserRouter, Switch } from 'react-router-dom';

import OpenRoute from "./components/open-route";
import PrivateRoute from "./components/authentication";

import Login from "./pages/login";
import SignUp from "./pages/sign-up";
import PublicForm from "./pages/public-form";
import VerifyUser from "./pages/verify-user";
import AzureLogin from "./pages/azure-login";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";

import LogOut from "./pages/logout";
import Dashboard from "./pages/dashboard/dashboard";
import Template from "./pages/dashboard/template";
import Drafts from "./pages/dashboard/drafts";
import Output from "./pages/dashboard/output";
import Logs from "./pages/dashboard/logs";
import Users from "./pages/dashboard/users";
import Paragraph from "./pages/dashboard/paragraph";
import Profile from "./pages/dashboard/profile";
import Image from "./pages/dashboard/image";
import Integrate from "./pages/dashboard/integrate";
import Automation from "./pages/dashboard/automation";
import Integrations from "./pages/dashboard/integrations";
import * as serviceWorker from './serviceWorker';
import TemplateVersion from "./pages/dashboard/template-version";

require("./static/css/base.css");
require("react-js-cron/dist/styles.css");
require('react-s-alert/dist/s-alert-default.css');
// optional - you can choose the effect you want
require('react-s-alert/dist/s-alert-css-effects/slide.css');

const nanobar = React.createRef();
ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <OpenRoute nanobar={nanobar} path="/login/" component={Login}/>
            <OpenRoute nanobar={nanobar} path="/sign-up/" exact component={SignUp}/>
            <OpenRoute nanobar={nanobar} path="/forgot-password/" exact component={ForgotPassword}/>
            <OpenRoute nanobar={nanobar} path="/reset-password/:token/" component={ResetPassword}/>
            <OpenRoute nanobar={nanobar} path="/verify-email/:token/" component={VerifyUser}/>
            <OpenRoute nanobar={nanobar} path="/azure-login/" component={AzureLogin}/>
            <OpenRoute nanobar={nanobar} path="/public-form/:templateId/:draftId/" component={PublicForm}/>
            <OpenRoute nanobar={nanobar} path="/public-form/:templateId/" component={PublicForm}/>

            <PrivateRoute nanobar={nanobar} path="/template/version/:templateId/" exact component={TemplateVersion}/>
            <PrivateRoute nanobar={nanobar} path="/integrate/:platform/" exact component={Integrate}/>
            <PrivateRoute nanobar={nanobar} path="/logout/" exact component={LogOut}/>
            <PrivateRoute nanobar={nanobar} path="/template/" exact component={Template}/>
            <PrivateRoute nanobar={nanobar} path="/images/" exact component={Image}/>
            <PrivateRoute nanobar={nanobar} path="/paragraph/" exact component={Paragraph}/>
            <PrivateRoute nanobar={nanobar} path="/drafts/" exact component={Drafts}/>
            <PrivateRoute nanobar={nanobar} path="/output/" exact component={Output}/>
            <PrivateRoute nanobar={nanobar} path="/logs/" exact component={Logs}/>
            <PrivateRoute nanobar={nanobar} path="/profile/" exact component={Profile}/>
            <PrivateRoute nanobar={nanobar} path="/users/" exact component={Users}/>
            <PrivateRoute nanobar={nanobar} path="/integrations/" exact component={Integrations}/>
            <PrivateRoute nanobar={nanobar} path="/automation/" exact component={Automation}/>
            <PrivateRoute nanobar={nanobar} path="/" exact component={Dashboard}/>
        </Switch>
        <Alert stack={{limit: 3}} />
        <Nanobar ref={nanobar} color="#33A913"/>
    </BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
