import React, { Fragment, useEffect, useState } from 'react';

import Head from '../../components/head';
import Header from '../../components/dashboard/header';
import SideNav from '../../components/dashboard/side-navbar';

import { RequestHook } from '../../components/hookRequest';
import { useContextData } from "../../store/ContextData";
import { commonFunction } from '../../components/common-functions';

import refresh from "../../static/svg/ring-alt.svg"


const TemplateVersion = (props) => {
  const {match} = props;
  const {params: {templateId}} = match;

  const { userRole } = useContextData();
  const [buttonId, setButtonId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [templateInfo, setTemplateInfo] = useState({});
  const {data: templateData, loading: templateLoading} = RequestHook(`api/v1/document/${templateId}`);
  useEffect(() => {
    if(templateData && !templateLoading){
      const {document} = templateData;
      if(document && document.length > 0){
        const [templateInfo] = document;
        setTemplateInfo(templateInfo);
        setIsLoading(false);
      }
    }
  }, [templateData, templateLoading]);

  const { filename, addedAt, size, shared_with, isFlatten } = templateInfo;
  const { getIcon, getDateFormat, getFileSize, formatDate } = commonFunction;

  const allVersion = [{
    _id: "version-1",
    name: "Version 1",
    date: new Date("11/01/2024"), 
    isCurrentVersion: false,
    description: "This is a sample description of the version."
  }, {
    _id: "version-2",
    name: "Version 2",
    date: new Date("11/21/2024"), 
    isCurrentVersion: true,
    description: "This is a sample description of the version."
  }, {
    _id: "version-3",
    name: "Version 3",
    date: new Date("12/01/2024"), 
    isCurrentVersion: false,
    description: "This is a sample description of the version."
  }, {
    _id: "version-4",
    name: "Version 4",
    date: new Date("12/07/2024"), 
    isCurrentVersion: false,
    description: "This is a sample description of the version."
  }];

  return (
    <div>
      <Head customTitle={"Template Version"}/>
      <Header></Header>
      <SideNav index={2}></SideNav>
      <main className="dashboard-container template">
        <div className="main-container">
          {isLoading && 
            <div className="public-form">
              <div className="loading-screen">
                <div className="flex-container vertical-middle full-height">
                  <div className="flexbox text-center">
                    <div className="loading">
                      <img src={refresh} className="loading" alt="loading"/>
                      <span id="loading" className="text-left">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {!isLoading && (
            <Fragment>
              <div className="template-details">
                <div className="icon-container">
                  <img src={getIcon(filename)} className="icon" alt="template type"/>
                </div>
                <div className="name-size-block">
                  <h1 className="name">{filename}</h1>
                  <div className="date-size">
                    <span className="text-uppercase">{getDateFormat(addedAt)}</span>
                    <span className="separator">-</span>
                    <span className="text-uppercase">{getFileSize(size)}</span>
                  </div>
                  <div className="key-value">
                    {userRole !== "3" && 
                      <Fragment>
                        <div className="key">Shared with</div>
                        <div className="value">
                        {
                          shared_with.reduce((data, key) => {
                            if(key === "2" && userRole !== 1){
                              return data;
                            }

                            data.push(key === "2" ? "Manager" : "User");
                            return data;
                          }, []).join(", ")
                        }
                        </div>
                      </Fragment>
                    }
                    <div className="key">Template is flatten</div>
                    <div className="value">{isFlatten ? "True" : "False"}</div>
                  </div>
                </div>
              </div>
              <h2 className="heading">All versions</h2>
              <h4 className="sub-heading">Find all available versions of the uploaded template.</h4>
              <ul className="timeline-block">
                {
                  allVersion.map(version => {
                    const { _id, name, date, isCurrentVersion, description } = version;
                    return (
                      <li className={isCurrentVersion ? "current-version" : ""} key={_id}>
                        <div className="time text-uppercase">{formatDate(date)}</div>
                        <span className="dot"></span>
                        <div className="title">{name}</div>
                        <div className="description">{description}</div>

                        <div className={`buttons ${buttonId === _id ? "open" : ""}`}>
                          <button
                            className="btn bullet"
                            onClick={() => setButtonId(id => (id && id === _id) ? "" : _id)}
                          >
                            <span>&bull;&bull;&bull;</span>
                          </button>
                          <div className="actions">
                            <ul>
                              {!isCurrentVersion && 
                                <li onClick={() => {
                                  setButtonId("");
                                }}>Mark as current</li>
                              }
                              <li onClick={() => {
                                setButtonId("");
                                // action('delete', elid);
                              }}>Delete...</li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    );
                  })
                }
              </ul>
            </Fragment>
          )}
        </div>
      </main>
    </div>
  )
};

export default TemplateVersion;
